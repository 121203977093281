export default {
    "breadcrumbs": {
        "explore": "Esplora",
    },
    "list-page": {
        "title": "Esplora a",
        "text-count-list": "{count} luoghi trovati",
        "text-count-list-search": "{count} posti trovati per",
        "text-count-list-search-active": "Cerca in tutti i luoghi",
        "text-search-not-found": "Perfeziona la ricerca o prova un'altra categoria",
        "btn-load-more": "Carica altri"
    },
    "results": "Nessun risultato...",
    "seaching": "Ricerca in corso...",
    "categorySearch": "trovato",
    "city": "Città",
    "placeholder-search":"Scrivi qualcosa",
    "types": {
        "que-visitar": "Cosa visitare",
        "donde-comer": "Dove mangiare",
        "ocio": "Tempo libero"
    },
    "categories": {
        "monumentos": "Monumenti",
        "museos": "Musei",
        "zonas-verdes": "Zone verdi",
        "cafeterias-y-postres": "Caffetterie e dolci",
        "restaurantes": "Ristoranti",
        "vida-nocturna": "Vita notturna",
        "copas": "Bicchieri",
        "compras": "Shopping",
        "otros": "Altri",
    },
    "detail": {
        "titleList": "Esplora",
        "opinionsTitle": "Opinioni sull'informazione turistica",
        "opinionsWord": "Opinioni",
        "recommendationWord": "Raccomandazione",
        "featured": "In evidenza",
        "callWord": "Chiama",
        "dataInterest": {
            "title": "Dati di interesse",
            "priceRange": "Fasce di prezzo",
            "diet": "Diete speciali",
            "others": "Altri",
        },
        "specialRegimes": "Regimi speciali",
        "additionalInformation": "Informazioni aggiuntive",
        "copy": "Copiare",
        "showLess": "Mostra meno",
        "showMore": "Mostra di più",
        "recommended": "Raccomandato",
        "buttoms": {
            "web": "Web",
            "call": "Chiamare",
            "menu": "Menu",
            "email": "E-mail",
        },
        "hours": {
            "title": "Orari",
            "monday": "lunedì",
            "tuesday": "martedì",
            "wednesday": "mercoledì",
            "thursday": "giovedì",
            "friday": "venerdì",
            "saturday": "sabato",
            "sunday": "domenica"
        },
        "locationWord": "Località",
        "starWord": "Stella",
        "starsWord": "Stelle",
        "ratingTitle": "Valutazione",
        "noComment": "Nessun commento scritto",
        "seeMoreComment": "Vedi altro",
        "seeAllComments": "Vedi tutte le opinioni",
        "titleAllReviews": "Opinioni sull'informazione turistica",
        "exploreIn": "Esplora a",
        "filters": {
            "title": "Filtra per",
            "ratings": "Valutazioni",
            "distanceLodding": "Distanza di alloggio",
            "recommended": "Consigliato",
            "score": "Punteggio",
            "star": "stella",
            "remove": "Rimuovi tutto",
            "apply": "Applica filtri",
            "distante": {
                "oneKm": "Tra 1 km e 3 km",
                "threeKm": "Tra 3 km e 5 km",
                "fiveKm": "Tra 5 km e 10 km",
                "tenKm": "10 km+"
            }
        }
    },
}
