export default {
    "textToast": {
        "sendQueryText": "Nous apprécions sincèrement vos commentaires. Votre expérience compte pour nous et nous nous efforçons de répondre à vos attentes.",
        "updateQueryText" : "Modification envoyée",
    },
    "form": {
        "send": "Envoyer",
        "cancel" : "Annuler",
        "title": "Demandes",
        "hello": "Bonjour",
        "whatsup": "Comment ça va",
        "thanksAll" : "Merci pour tout",
        "btn-verywrong-stay": "Très mauvais",
        "btn-wrong-stay": "Mauvais",
        "btn-normal-stay": "Normal",
        "btn-good-stay": "Bien",
        "btn-verygood-stay": "Très bien",
        "btn-verywrong-poststay": "Très mauvais",
        "btn-wrong-poststay": "Mauvais",
        "btn-normal-poststay": "Normal",
        "btn-good-poststay": "Bien",
        "btn-verygood-poststay": "Très bien",
        "poststay-bad-thanks-title": "Réponse envoyée", 
        "poststay-bad-thanks-subtitle": "Merci beaucoup pour votre temps",
    },
    "settings": {
        "questionpre-stay": "Y a-t-il quelque chose que nous pouvons faire pour votre arrivée à l'hôtel?",
        "questionin-stay": "Comment évalueriez-vous votre niveau de satisfaction avec votre séjour jusqu'à présent?",
        "questionpost-stay": "Comment a été votre expérience avec nous?",
    },
}
