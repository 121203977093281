export default {
    "cmn": "Mandarin",
    "es": "Spanisch",
    "en": "Englisch",
    "hi": "Hindi",
    "ar": "Arabisch",
    "pt": "Portugiesisch",
    "bn": "Bengalisch",
    "ru": "Russisch",
    "ja": "Japanisch",
    "pa": "Panjabi",
    "de": "Deutsch",
    "jv": "Javanisch",
    "wuu": "Wu Chinesisch",
    "ms": "Malaiisch",
    "te": "Telugu",
    "vi": "Vietnamesisch",
    "ko": "Koreanisch",
    "fr": "Französisch",
    "mr": "Marathi",
    "ta": "Tamil",
    "ur": "Urdu",
    "tr": "Türkisch",
    "it": "Italienisch",
    "yue": "Kantonesisch",
    "th": "Thailändisch",
    "gu": "Gujarati",
    "mnp": "Chinesisch Min Bei",
    "fa": "Farsi",
    "pl": "Polnisch",
    "ps": "Paschtu",
    "hsn": "Hunanisch",
    "ml": "Malayalam",
    "my": "Birmanisch",
    "hak": "Hakka Chinesisch",
    "uk": "Ukrainisch",
    "fil": "Filipino",
    "ro": "Rumänisch",
    "nl": "Niederländisch",
    "ku": "Kurdisch",
    "hr": "Kroatisch",
    "hu": "Ungarisch",
    "el": "Griechisch",
    "cs": "Tschechisch",
    "sv": "Schwedisch",
    "fi": "Finnisch",
    "sk": "Slowakisch",
    "sr": "Serbisch",
    "no": "Norwegisch",
    "he": "Hebräisch",
    "da": "Dänisch",
    "zh_CN": "Vereinfachtes Chinesisch",
    "title": "Sprachpräferenz"
}
