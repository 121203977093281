export default {
    "account": "Il mio account",
    "greeting": "Ciao, {name}!",
    "my_stays": {
        "title": "I miei soggiorni",
        "subtitle_active": "Gestisci i tuoi soggiorni",
        "subtitle_inactive": "Crea il tuo prossimo soggiorno"
    },
    "personal_info": {
        "title": "Informazioni personali",
        "description": "Configura le tue informazioni personali"
    },
    "language" : {
        "title": "Lingua",
        "description": "Seleziona la lingua predefinita"
    },
    "logout": "Disconnettersi",
    "share_stay": "Condividi Soggiorno"
};
