export default {
    "account": "Mon compte",
    "greeting": "Bonjour, {name}!",
    "my_stays": {
        "title": "Mes séjours",
        "subtitle_active": "Gérez vos séjours",
        "subtitle_inactive": "Créez votre prochain séjour"
    },
    "personal_info": {
        "title": "Informations personnelles",
        "description": "Configurez vos informations personnelles"
    },
    "language" : {
        "title": "Langue",
        "description": "Sélectionnez votre langue par défaut"
    },
    "logout": "Se déconnecter",
    "share_stay": "Partager le Séjour"
};
