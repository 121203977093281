export default {
    "cmn": "mandarin",
    "es": "spanish",
    "en": "english",
    "hi": "hindi",
    "ar": "arabic",
    "pt": "portuguese",
    "bn": "bengali",
    "ru": "russian",
    "ja": "japanese",
    "pa": "panjabi",
    "de": "german",
    "jv": "javanese",
    "wuu": "wu chinese",
    "ms": "malay",
    "te": "telugu",
    "vi": "vietnamese",
    "ko": "korean",
    "fr": "french",
    "mr": "marathi",
    "ta": "tamil",
    "ur": "urdu",
    "tr": "turkish",
    "it": "italian",
    "yue": "cantonese",
    "th": "thai",
    "gu": "gujarati",
    "mnp": "chinese min bei",
    "fa": "farsi",
    "pl": "polish",
    "ps": "pushto",
    "hsn": "hunanese",
    "ml": "malayalam",
    "my": "burmese",
    "hak": "hakka chinese",
    "uk": "ukrainian",
    "fil": "filipino",
    "ro": "romanian",
    "nl": "dutch",
    "ku": "kurdish",
    "hr": "croatian",
    "hu": "hungarian",
    "el": "greek",
    "cs": "czech",
    "sv": "swedish",
    "fi": "finnish",
    "sk": "slovak",
    "sr": "serbian",
    "no": "norwegian",
    "he": "hebrew",
    "da": "danish",
    "zh_CN": "chinese simplified",
    "title": "Language Preference",
}