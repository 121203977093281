export default {
    "breadcrumbs": {
        "experiences": "Experiências",
    },
    "card-experience": {
        "hour": "horas",
        "aprox": "aproximadamente",
        "min-aprox": "minutos aproximadamente",
        "and": "e",
        "recommed": "recomendado",
        "prominent": "proeminente",
        "annulation-gratuite": "cancelamento grátis",
        "non-refundable": "não reembolsável",
        "from":"De",
    },
    "list-page": {
        "title": "Experiências",
        "section-filter": {
            "label-search": "Filtrar por",
            "placeholder-input-search": "Insira uma palavra-chave",
            "label-price": "Preço",
            "label-price-min": "Preço Mín.",
            "placeholder-price-max": "Mínimo..",
            "label-price-max": "Preço Máx.",
            "placeholder-price-max": "Máximo..",
            "label-duration": "Duração",
            "cancellation-policy": {
                "title": "Política de cancelamento",
                "free-cancellation": "Cancelamento gratuito"
              },
              "duration": {
                "title": "Duração",
                "one-hour": "Até 1 hora",
                "one-four-hour": "Entre 1 e 4 horas",
                "noon": "Meio dia",
                "whole-day": "Dia inteiro"
              },
              "score": {
                "title": "Pontuação",
                "star": "Estrelas"
              },
              "price": {
                "title": "Preço",
                "0-100": "Entre 0 € e 100 €",
                "100-250": "Entre 100 € e 250 €",
                "250-500": "Entre 250 € e 500 €",
                "500": "+500 €"
              }
        },
        "section-filter-history": {
            "btn-price": "Até {price_max}",
            "btn-close": "Remover filtro"
        },
        "btn-more-filter": "Mais filtros",
        "btn-submit-filter": "Aplicar filtros",
        "text-count-list": "Experiências mistas",
        "text-count-list-mobile": "{count} Experiências encontradas.",
        "text-count-list-search-active": "Buscar todas as Experiências para",
        "experience.placeholder-search": "Buscar uma experiência",
        "text-count-list-search": "{count} Experiências encontradas para",
        "text-search-not-found": "Ajuste sua busca ou tente remover os filtros.",
        "without-results": {
            "title": "Nenhuma experiência encontrada",
            "text": "Nenhuma experiência encontrada nesta cidade. Convidamos você a explorar outras cidades, onde você descobrirá várias experiências e atividades."
        },
        "btn-load-more": "Carregar mais"
    },
    "detail-page": {
        "tag-recommend": "Recomendado",
        "tag-featured": "Destaque",
        "text-variand-price": "O preço varia dependendo do tamanho do grupo",
        "tag-ticket-mobile": "Bilhete Móvel",
        "title-recomendation": "Recomendação",
        "title-description": "Descrição",
        "title-description-mobile": "Visão Geral",
        "tooltip-language": "{language} e mais {numbers}",
        "open-collapse-description": "Ver mais",
        "close-collapse-description": "Ver menos",
        "title-include": "O que está incluído",
        "title-location": "Localização",
        "btn-eye-map": "Ver no Google Maps",
        "subtitle-point-start": "Ponto de partida",
        "subtitle-point-end": "Ponto final",
        "ends-point-at-meeting-point": "Esta atividade termina no ponto de encontro inicial",
        "title-other-information": "Informações adicionais",
        "title-politic-cancelation": "Política de cancelamento",
        "text-politic-standar-1": "Você pode cancelar até 24 horas antes da experiência para um reembolso total.",
        "text-politic-standar-2": "Para obter um reembolso total, você deve cancelar pelo menos 24 horas antes do horário de início da experiência.",
        "text-politic-standar-3": "Quaisquer alterações feitas menos de 24 horas antes do horário de início da experiência não serão aceitas.",
        "text-politic-standar-4": "Se você cancelar pelo menos 24 horas antes do horário de início da experiência, o valor pago não será reembolsado.",
        "text-politic-standar-5": "Os horários de corte são baseados no horário local da experiência.",
        "text-politic-notrembolsable": "Esta experiência não é reembolsável e não pode ser alterada por nenhum motivo. Se você cancelar ou solicitar uma modificação, o valor pago não será reembolsado.",
        "opinions-word":"opiniões",
        "variant-price":"O preço varia dependendo do tamanho do grupo",
        "when-experience-label":"Quando você deseja desfrutar desta experiência?",
        "maximum-reserves-label":"Você pode reservar no máximo {maxSeats} assentos",
        "availability-label":"Ver disponibilidade",
        "free-cancellation-label":"Cancelamento gratuito",
        "non-refundable-cancellation-label":"Não reembolsável",

        "check-the-availability-of-this-experience": "Verifique a disponibilidade desta experiência",
        "btn-see-availability-on-viator": "Veja a disponibilidade no Viator",
        "title-general-information": "Informações gerais",
        "tag-paper-ticket": "Ingresso impresso",
        "offered-in": "Oferecido em"
    }
}
