export default {
    "account": "Minha conta",
    "greeting": "Olá, {name}!",
    "my_stays": {
        "title": "Minhas estadias",
        "subtitle_active": "Gerencie suas estadias",
        "subtitle_inactive": "Crie sua próxima estadia"
    },
    "personal_info": {
        "title": "Informações pessoais",
        "description": "Configure suas informações pessoais"
    },
    "language" : {
        "title": "Preferência de idioma",
        "description": "Selecione seu idioma padrão"
    },
    "logout": "Sair",
    "share_stay": "Compartilhar Estadia"
};
