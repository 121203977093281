export default {
    "breadcrumbs": {
        "experiences": "Erfahrungen",
    },
    "card-experience": {
        "hour": "stunden",
        "aprox": "ungefähr",
        "min-aprox": "ungefähr Minuten",
        "and": "und",
        "recommed": "empfohlen",
        "prominent": "herausragend",
        "annulation-gratuite": "kostenlose Stornierung",
        "non-refundable": "nicht erstattungsfähig",
        "from":"Von",
    },
    "list-page": {
        "title": "Erfahrungen",
        "section-filter": {
            "label-search": "Filtern nach",
            "placeholder-input-search": "Schlüsselwort eingeben",
            "label-price": "Preis",
            "label-price-min": "Min. Preis",
            "placeholder-price-max": "Minimum..",
            "label-price-max": "Max. Preis",
            "placeholder-price-max": "Maximum..",
            "label-duration": "Dauer",
            "cancellation-policy": {
                "title": "Stornierungsrichtlinie",
                "free-cancellation": "Kostenlose Stornierung"
              },
              "duration": {
                "title": "Dauer",
                "one-hour": "Bis zu 1 Stunde",
                "one-four-hour": "Zwischen 1 und 4 Stunden",
                "noon": "Halber Tag",
                "whole-day": "Ganzer Tag"
              },
              "score": {
                "title": "Bewertung",
                "star": "Sterne"
              },
              "price": {
                "title": "Preis",
                "0-100": "Zwischen 0 € und 100 €",
                "100-250": "Zwischen 100 € und 250 €",
                "250-500": "Zwischen 250 € und 500 €",
                "500": "+500 €"
              }
        },
        "section-filter-history": {
            "btn-price": "Bis zu {price_max}",
            "btn-close": "Filter entfernen"
        },
        "btn-more-filter": "Weitere Filter",
        "btn-submit-filter": "Filter anwenden",
        "text-count-list": "Gemischte Erfahrungen",
        "text-count-list-mobile": "{count} Erfahrungen gefunden.",
        "experience.placeholder-search": "Nach einer Erfahrung suchen",
        "text-count-list-search-active": "Alle Erlebnisse suchen für",
        "text-count-list-search": "{count} Erlebnisse gefunden für",
        "text-search-not-found": "Passen Sie Ihre Suche an oder versuchen Sie, Filter zu entfernen.",
        "without-results": {
            "title": "Keine Erfahrungen gefunden",
            "text": "In dieser Stadt wurden keine Erfahrungen gefunden. Wir laden Sie ein, andere Städte zu erkunden, wo Sie verschiedene Erfahrungen und Aktivitäten entdecken können."
        },
        "btn-load-more": "Mehr laden"
    },
    "detail-page": {
        "tag-recommend": "Empfohlen",
        "tag-featured": "Hervorgehoben",
        "text-variand-price": "Der Preis variiert je nach Gruppengröße",
        "tag-ticket-mobile": "Mobiles Ticket",
        "title-recomendation": "Empfehlung",
        "title-description": "Beschreibung",
        "title-description-mobile": "Überblick",
        "tooltip-language": "{language} und {numbers} mehr",
        "open-collapse-description": "Mehr sehen",
        "close-collapse-description": "Weniger anzeigen",
        "title-include": "Was ist inbegriffen",
        "title-location": "Standort",
        "btn-eye-map": "Auf Google Maps ansehen",
        "subtitle-point-start": "Startpunkt",
        "subtitle-point-end": "Endpunkt",
        "ends-point-at-meeting-point": "Diese Aktivität endet am ursprünglichen Treffpunkt",
        "title-other-information": "Weitere Informationen",
        "title-politic-cancelation": "Stornierungsbedingungen",
        "text-politic-standar-1": "Sie können bis zu 24 Stunden vor der Erfahrung stornieren, um eine vollständige Rückerstattung zu erhalten.",
        "text-politic-standar-2": "Um eine vollständige Rückerstattung zu erhalten, müssen Sie mindestens 24 Stunden vor dem Startzeitpunkt der Erfahrung stornieren.",
        "text-politic-standar-3": "Änderungen, die weniger als 24 Stunden vor dem Startzeitpunkt der Erfahrung gemacht werden, werden nicht akzeptiert.",
        "text-politic-standar-4": "Wenn Sie mindestens 24 Stunden vor dem Startzeitpunkt der Erfahrung stornieren, wird der gezahlte Betrag nicht erstattet.",
        "text-politic-standar-5": "Die Stichtagszeiten basieren auf der lokalen Zeit der Erfahrung.",
        "text-politic-notrembolsable": "Diese Erfahrung ist nicht erstattungsfähig und kann aus keinem Grund geändert werden. Wenn Sie stornieren oder eine Änderung anfordern, wird der gezahlte Betrag nicht erstattet.",
        "opinions-word":"Meinungen",
        "variant-price":"Der Preis variiert je nach Gruppengröße",
        "when-experience-label":"Wann möchten Sie diese Erfahrung genießen?",
        "maximum-reserves-label":"Sie können maximal {maxSeats} Plätze reservieren",
        "availability-label":"Verfügbarkeit sehen",
        "free-cancellation-label":"Kostenlose Stornierung",
        "non-refundable-cancellation-label":"Nicht erstattungsfähig",

        "check-the-availability-of-this-experience": "Überprüfen Sie die Verfügbarkeit dieser Erfahrung",
        "btn-see-availability-on-viator": "Verfügbarkeit auf Viator anzeigen",
        "title-general-information": "Allgemeine Informationen",
        "tag-paper-ticket": "Papier-Ticket",
        "offered-in": "Angeboten in"
    }
}
