export default {
    "account": "Mein Konto",
    "greeting": "Hallo, {name}!",
    "my_stays": {
        "title": "Meine Aufenthalte",
        "subtitle_active": "Verwalten Sie Ihre Aufenthalte",
        "subtitle_inactive": "Erstellen Sie Ihren nächsten Aufenthalt"
    },
    "personal_info": {
        "title": "Persönliche Informationen",
        "description": "Richten Sie Ihre persönlichen Informationen ein"
    },
    "language" : {
        "title": "Sprache",
        "description": "Wählen Sie Ihre Standardsprache aus"
    },
    "logout": "Abmelden",
    "share_stay": "Aufenthalt Teilen"
};
