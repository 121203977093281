export default {
    "account": "My account",
    "greeting": "Hello, {name}!",
    "my_stays": {
        "title": "My stays",
        "subtitle_active": "Manage your stays",
        "subtitle_inactive": "Create your next stay"
    },
    "personal_info": {
        "title": "Personal information",
        "description": "Set up your personal information"
    },
    "language" : {
        "title": "Language",
        "description": "Select your default language"
    },
    "logout": "Log out",
    "share_stay": "Share Stay"
};
